import { Container, makeStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import React from "react"
import Layout from "../components/Layout"
import Section from "../components/Section"
import Seo from "../components/Seo"

const useStyles = makeStyles({
  content: {
    textAlign: "left",
  },
  inner: {
    marginTop: 32,
    marginBottom: 32,
  },
})

const LegalNotice = ({}) => {
  const classes = useStyles()
  return (
    <Layout>
      <Seo title={"Legal Notice"} />

      <Section>
        <Container maxWidth={"md"} className={classes.content}>
          <Typography variant={"h1"}>Legal Notice</Typography>

          <div className={classes.inner}>
            <Typography>NeverNull GmbH</Typography>
            <Typography>Löwengasse 4</Typography>
            <Typography>71134 Aidlingen</Typography>
            <Typography>GERMANY</Typography>
            <Typography>+49-7034-9422225</Typography>
            <Typography>info@nevernull.io</Typography>
            <div className={classes.inner}>
              <Typography variant={"h3"}>Managing Director</Typography>
              <Typography>Dr. Daniel Thommes</Typography>
            </div>
            <Typography variant={"h3"}>Court of registration</Typography>
            <Typography>Stuttgart | HRB 765754</Typography>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default LegalNotice
